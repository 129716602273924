import { ApiConfig } from "../config";

export class ApiClient {
  baseUrl: string;

  constructor(base: string) {
    this.baseUrl = base;
  }

  async get_signups_left(): Promise<number> {
    let resp: SignupsLeftResponse = await (
      await fetch(this.baseUrl + "/signups_left")
    ).json();

    return resp.signups_left;
  }

  async signup(email: string, captcha: string): Promise<SignupResponse> {
    let resp: SignupResponse = await (
      await fetch(this.baseUrl + "/signup", {
        body: JSON.stringify({ email, captcha }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
      })
    ).json();

    return resp;
  }
}

interface SignupsLeftResponse {
  signups_left: number;
}

export const apiClient = new ApiClient(ApiConfig.api_host_base);

interface SignupResponse {
  success: boolean;
  message: string | null;
}
