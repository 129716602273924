import React from "react";

// Layouts
import LandingLayout from "./layouts/Landing";

// Landing
import Landing from "./pages/landing/Landing";

const routes = [
  {
    path: "*",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
];

export default routes;
